<template></template>

<script setup>
import userAPI from '@/api/user';
import router from '@/router';
import store from '@/store';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';

const route = useRoute();
const $toast = useToast();

if (!route.query.token) {
    $toast.error('Link is invalid');
    router.replace({ name: "Login" });
}
try {
    userAPI.verifyEmail(route.query.token);
} finally {
    router.replace({ name: "UserProfile" });
}
</script>