<template>
  <div>
    <a-table
      :data-source="projects"
      :columns="columns"
      :pagination="{ pageSize: 20 }"
      :scroll="{ y: 600 }"
      ref="ref3"
    >
      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(confirm)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(confirm)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
              <router-link
                v-if="record.id"
                :to="{ name: 'ScreenList', params: { project_id: record.id } }"
              >
                {{ record.name }}
              </router-link>
        </template>

        <template v-if="column.key === 'links'">
          <router-link
            v-if="record.name"
            :to="{ name: 'Config', params: { project_id: record.id } }"
          >
            Config
          </router-link>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons-vue';

export default {
  name: 'SharedProjectListPage',
  components: {
    SearchOutlined,
    CheckOutlined,
    EditOutlined,
  },
  setup() {
    const store = useStore();
    const editableProject = ref({});
    const userId = store.getters.getUserID;

    const handleSearch = (confirm) => {
      confirm();
    };

    const handleReset = (clearFilters) => {
      clearFilters({ confirm: true });
    };

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        customFilterDropdown: true,
        onFilter: (value, record) =>
          record.name.toString().toLowerCase().includes(value.toLowerCase()),
      },
      {
        title: 'Links',
        dataIndex: 'links',
        key: 'links',
      },
    ];

    const projects = computed(() => store.getters.sharedProjectList);

    onMounted(() => {
      store.dispatch('getAllSharedProjects', userId);
    });

    return {
      editableProject,
      columns,
      projects,
      handleSearch,
      handleReset,
    };
  },
};
</script>

