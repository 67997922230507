<template>
  <div class="pl-5">
    <div class="dropzone-info" v-if="!onPageComponents.length">DROP ZONE</div>

    <draggable-component
      :list="list"
      group="component"
      tag="ul"
      item-key="name"
      class="draggable"
    >
      <template #item="{ element }">
        <div class="list-group-item">
          <FactoryComponent
            @click="click(element)"
            :key="element.id"
            :id="element.id"
            :memo="element.memo"
            :componentName="element.name"
            :componentDisplayName="element.display_name"
            :nestedComponents="element.nestedComponents"
            :data="element.data"
            :actions="element.actions"
            :styles="element.styles"
          ></FactoryComponent>
          <span class="remove" @click="remove(element.id)">X</span>
          <nested-constructor
            v-if="element.nestedComponents"
            :list="element.nestedComponents"
          />
        </div>
      </template>
    </draggable-component>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import FactoryComponent from './FactoryComponent';
import { removeObject } from 'find-and';

const props = defineProps({
  list: Array,
});

const store = useStore();

// Vuex state handling with computed properties
const onPageComponents = computed({
  get() {
    return store.getters.onPageComponents;
  },
  set(value) {
    store.commit('updateOnPageComponents', value);
  },
});

const currentConfiguredWidget = computed({
  get() {
    return store.getters.currentConfiguredWidget;
  },
  set(value) {
    store.commit('updatedCurrentConfiguredWidget', value);
  },
});

// Methods
const click = (evn) => {
  currentConfiguredWidget.value = evn;
};

const remove = (event_id) => {
  onPageComponents.value = removeObject(onPageComponents.value, { id: event_id });
};
</script>

<style scoped>
.drag-zone {
  background-color: aqua;
}

.list-group-item {
  position: relative;
}

.remove {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px;
  background-color: red;
  cursor: pointer;
}

.draggable {
  border: 1px dotted #BDBDBD;
  min-height: 50px;
}

.dropzone-info {
  position: sticky;
  text-align: center;
}
</style>
