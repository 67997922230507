<template>
    <div>
      <a-divider>Tokens</a-divider>
  
      <token-item ref="refProjectID" title="Project ID" :value="project?.id" placeholder="id" />
      <token-item ref="refProjectToken" title="Project Token" :value="project?.token" placeholder="token" />
      <token-item ref="refApiToken" title="API Token" :value="project?.api_token" placeholder="API token">
        <a-button v-if="!isShared" @click="$emit('generate-token')">Regenerate</a-button>
      </token-item>
    </div>
  </template>
  
  <script>
  import TokenItem from './TokenItem.vue';
  
  export default {
    props: ['project', 'isShared'],
    components: { TokenItem }
  };
  </script>
  