<template>
  <div>
    <div class="flex pb-2">
      <a-input name="name" type="text" v-model:value="newScreenName" placeholder="New Screen" class="mr-2" />
      <a-button @click="save" type="primary">Save</a-button>
    </div>

    <a-table :data-source="screens" :columns="columns" :pagination="{ pageSize: 20 }" :scroll="{ y: 600 }">
      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div class="p-2">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            class="w-48 mb-2" @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(confirm)" />
          <a-button type="primary" size="small" class="w-24 mr-2" @click="handleSearch(confirm)">
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button size="small" class="w-24" @click="handleReset(clearFilters)">
            Reset
          </a-button>
        </div>
      </template>

      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <div class="editable-cell">
            <div v-if="editableScreens[record.id]" class="editable-cell-input-wrapper">
              <a-input v-model:value="editableScreens[record.id].name" @pressEnter="update(record)" />
              <check-outlined class="editable-cell-icon-check" @click="update(record)" />
            </div>
            <div v-else class="editable-cell-text-wrapper">
              <router-link v-if="record.name" :to="{ name: 'Constructor', params: { screen_id: record.id } }">
                {{ record.name }}
              </router-link>
              <edit-outlined class="editable-cell-icon" @click="edit(record)" />
            </div>
          </div>
        </template>

        <template v-if="column.key === 'links'">
          <router-link v-if="record.name" :to="{ name: 'ScreenConfig', params: { screen_id: record.id } }">
            Config
          </router-link>
        </template>

        <template v-if="column.key === 'action'">
          <a-button @click="remove(record.id)" class="mr-2">Remove</a-button>
          <a-button @click="clone(record)">Clone</a-button>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex'; // Import useStore
import { SearchOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';

// Access Vuex store using useStore
const store = useStore();
const route = useRoute();

// Refs
const newScreenName = ref('');
const editableScreens = ref({});
const project_id = computed(() => route.params.project_id);

// Screens
const screens = computed({
  get() {
    return store.getters.screenList;
  },
  set(value) {
    store.commit('updatedScreenList', value);
  }
});

// Columns for the table
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    customFilterDropdown: true,
    onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase())
  },
  {
    title: 'Links',
    dataIndex: 'links',
    key: 'links',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
  }
];

// Methods
const save = () => {
  const data = { name: newScreenName.value, body: null };
  store.dispatch('createScreen', { project_id: project_id.value, data });
};

const remove = (screen_id) => {
  store.dispatch('deleteScreen', screen_id);
};

const clone = (screen) => {
  const data = {
    name: `${screen.name} copy`,
    body: { config: screen.config, nestedComponents: screen.nestedComponents },
  };
  store.dispatch('createScreen', { project_id: project_id.value, data });
};

const edit = (screen) => {
  editableScreens.value[screen.id] = {
    original_name: screen.name,
    ...screen,
  };
};

const update = (screen) => {
  const updated = editableScreens.value[screen.id];
  const params = { name: updated.name, nestedComponents: updated.nestedComponents };
  const body = { name: updated.original_name, body: params };
  store.dispatch('updateScreen', { screen_id: screen.id, data: body });
  delete editableScreens.value[screen.id];
};

const handleSearch = (confirm) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters({ confirm: true });
};

// Fetch all screens when mounted
onMounted(() => {
  store.dispatch('getAllScreens', project_id.value);
});
</script>

<style scoped>
.editable-cell {
  position: relative;
}

.editable-cell .editable-cell-input-wrapper,
.editable-cell .editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell .editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell .editable-cell-icon,
.editable-cell .editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell .editable-cell-icon {
  margin-top: 4px;
  display: none;
}

.editable-cell .editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell .editable-cell-icon:hover,
.editable-cell .editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>