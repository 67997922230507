<template>
    <a-divider>ENV</a-divider>
  
    <div class="flex justify-between pb-2 mb-2" ref="refDisplayName">
      <a-typography-title :level="5">Display Name</a-typography-title>
      <div>
        <a-input-group compact class="!flex">
          <a-input v-model:value="config.system_env.display_name" placeholder="My App" />
          <a-button @click="displayNameSubmit" type="primary">Submit</a-button>
        </a-input-group>
      </div>
    </div>
  
    <div class="flex justify-between pb-2 mb-2" ref="refPackageName">
      <a-typography-title :level="5">Package Name</a-typography-title>
      <div>
        <a-input-group compact class="!flex">
          <a-input v-model:value="config.system_env.package_name" placeholder="com.company.my_app" />
          <a-button @click="packageNameSubmit" type="primary">Submit</a-button>
        </a-input-group>
      </div>
    </div>
  
    <div v-for="(env, index) in userEnvForm" :key="index">
      <div class="flex justify-between pb-2 mb-2">
        <a-input class="w-44 h-8" v-model:value="env.name" placeholder="HOST" />
        <div>
          <a-input-group compact class="!flex">
            <a-input v-model:value="env.value" placeholder="http://localhost" />
            <a-button @click="addUserEnv(env)" type="primary">Submit</a-button>
            <a-button @click="deleteUserEnv(env)" danger>Remove</a-button>
          </a-input-group>
        </div>
      </div>
    </div>
  
    <div class="flex items-center justify-center">
      <a-button @click="addUserEnvRow" type="primary">Add</a-button>
    </div>
  </template>
  
  <script>
  import { ref, watch } from 'vue';
  
  export default {
    name: 'EnvironmentConfig',
    props: {
      config: Object,
      project_id: String
    },
    setup(props, { emit }) {
      const userEnvForm = ref([]);
  
      // Initialize user environment form when config changes
      watch(
        () => props.config.user_env,
        (newUserEnv) => {
          if (newUserEnv) {
            userEnvForm.value = Object.keys(newUserEnv).map((key) => ({
              name: key,
              value: newUserEnv[key],
            }));
          }
        },
        { immediate: true }
      );
  
      // Handle submitting package name change
      const packageNameSubmit = () => {
        if (!props.config.system_env.package_name) return;
        emit('update-config', {
          name: 'package_name',
          value: props.config.system_env.package_name,
          project_id:  props.project_id
        });
      };
  
      // Handle submitting display name change
      const displayNameSubmit = () => {
        if (!props.config.system_env.display_name) return;
        emit('update-config', {
          name: 'display_name',
          value: props.config.system_env.display_name,
          project_id:  props.project_id
        });
      };
  
      // Handle adding a new environment variable
      const addUserEnvRow = () => {
        userEnvForm.value.push({ name: '', value: '' });
      };
  
      // Handle adding environment variable data
      const addUserEnv = (env) => {
        if (!env.name || !env.value) return;
        emit('add-env', env);
      };
  
      // Handle deleting an environment variable
      const deleteUserEnv = (env) => {
        emit('delete-env', env);
        userEnvForm.value = userEnvForm.value.filter((e) => e !== env);
      };
  
      return {
        userEnvForm,
        packageNameSubmit,
        displayNameSubmit,
        addUserEnvRow,
        addUserEnv,
        deleteUserEnv,
      };
    },
  };
  </script>
  