<template>
    <div class="flex flex-col items-center w-full relative px-4 hidden md:flex">
        <a-input :style="searchInputStyle" v-model:value="search" name="search" placeholder="Search Docs"></a-input>
        <!-- Dropdown for search results -->
        <div v-if="searchResults.length" class="search-dropdown">
            <ul>
                <li v-for="result in searchResults" :key="result.id" @click="handleResultClick(result)">
                    <div class="result-name">{{ result.name }}</div>
                    <div class="result-description">{{ result.description }}</div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { miniSearch } from '@/utils/apiDocs';
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router'; // Import useRouter from vue-router

export default {
    name: 'GlobalDocSearchInput',
    setup() {
        const search = ref('');
        const searchResults = ref([]);
        const router = useRouter(); // Get the router instance

        // Watch for changes in the `search` input
        watch(search, async (newValue) => {
            if (newValue) {
                // Fetch search results from miniSearch
                searchResults.value = miniSearch.search(newValue);
            } else {
                // Clear search results if input is empty
                searchResults.value = [];
            }
        });

        // Define `searchInputStyle` as a computed property
        const searchInputStyle = computed(() => ({
            backgroundColor: '#001529',
            borderColor: '#616161',
            color: '#fff',
            width: '500px'
        }));

        // Handle when a search result is clicked
        const handleResultClick = (result) => {
            router.push(result.route);
            search.value = '';
            searchResults.value = [];
        };

        return {
            search,
            searchResults,
            searchInputStyle,
            handleResultClick
        };
    }
}
</script>

<style scoped>
/* Placeholder styles for the input */
::placeholder {
    color: #cfd8dc;
    opacity: 1;
}

/* Search input styles */
.search-input {
    background-color: #001628;
    border: 1px solid #455a64;
    color: #ffffff;
    width: 500px;
    border-radius: 4px;
    padding: 12px 15px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

/* Input focus effect */
.search-input:focus {
    outline: none;
    border-color: #0078d4;
    box-shadow: 0 0 0 3px rgba(0, 120, 212, 0.3);
}

/* Dropdown styles */
.search-dropdown {
    background-color: #001628;
    color: #ffffff;
    position: absolute;
    margin-top: 5px;
    width: 500px;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid #455a64;
    z-index: 1000;
    padding: 5px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Styles for the dropdown list */
.search-dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Dropdown item styles */
.search-dropdown li {
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 5px;
    transition: background-color 0.3s, transform 0.2s;
}

/* Dropdown item hover effect */
.search-dropdown li:hover {
    background-color: #1677FF;
    transform: scale(1.02);
}

/* Style for the result title */
.result-name {
    font-weight: bold;
    font-size: 1rem;
    color: #ffffff;
    line-height: 1.4;
}

/* Style for the result description */
.result-description {
    font-size: 0.9rem;
    color: #b3b3b3;
    margin-top: 2px;
    line-height: 1.4;
}
</style>
