import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
const base_path = '/api/admin/v1/screens';
const update_and_broadcast_path = '/api/admin/v1/screens/update-and-broadcast';

const screenAPI = {
  async getAllScreens(project_id) {
    return api
      .get(`/api/admin/v1/screens_list/${project_id}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async getAllPublishedScreens() {
    return api
      .get(`/api/admin/v1/screens/published`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async getPublishedScreenById(id) {
    return api
      .get(`/api/admin/v1/screens/published/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async getScreen(screen_id) {
    return api
      .get(`${base_path}/${screen_id}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async createScreen(project_id, data) {
    return api
      .post(base_path, { project_id: project_id, screen: data })
      .then((response) => {
        $toast.success('Screen Created');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async updateScreen(screen_id, data) {
    return api
      .put(`${base_path}/${screen_id}`, { screen: data.body })
      .then((response) => {
        if (response.data.nestedComponents) {
          $toast.success('Screen Updated');
        }
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async publishScreenToStore({ screen_id, title, description }) {
    return api
      .post(`${base_path}/publish_screen_to_store`, { id: Number(screen_id), title: title, description: description })
      .then((response) => {
        if (response.data) {
          $toast.success('Screen Published');
        }
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async installScreenFromStore({ id, project_id }) {
    return api
      .post(`${base_path}/install_screen_from_store`, { id: id, project_id: project_id })
      .then((_) => {
        $toast.success('Screen Installed');
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async generateAIContent({ prompt }) {
    return api
      .post(`${base_path}/generate_ai_content`, { prompt })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async updateAndBroadcastScreen(screen_id, data) {
    return api
      .put(`${update_and_broadcast_path}/${screen_id}`, { screen: data.body })
      .then((response) => {
        if (response.data.nestedComponents) {
          $toast.success('Screen Updated');
        }
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async deleteScreen(screen_id) {
    return api
      .delete(`${base_path}/${screen_id}`)
      .then((response) => {
        $toast.success('Screen Deleted');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
};

export default screenAPI;
