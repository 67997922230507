import { createStore } from 'vuex';
// import createPersistedState from "vuex-persistedstate";
import pushEvent from './modules/pushEvent.js';
import activeUsers from './modules/activeUsers.js';
import actions from './modules/actions.js';
import notification from './modules/notification.js';
import constructorState from './modules/constructor.js';
import projects from './modules/projects.js';
import config from './modules/config.js';
import auth from './modules/auth.js';
import user from './modules/user.js';
import wormhole from './modules/wormhole.js';
import screen_store from './modules/screenStore.js';

export default createStore({
  modules: {
    constructorState,
    notification,
    config,
    pushEvent,
    activeUsers,
    actions,
    projects,
    auth,
    user,
    wormhole,
    screen_store,
  },
  mutations: {
    resetState(state) {
      state.projects.collaboratorList = [];
      state.projects.projectList = [];
      state.projects.sharedProjectList = [];
    },
  },
  actions: {
    resetStore({ commit }) {
      commit('resetState');
    },
  },
});
