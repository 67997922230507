import router from '@/router';
import store from '@/store';
import axios from 'axios';

const api = axios.create({
  baseURL: '/',
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Check if user is authenticated (you can adjust this according to your store structure)
    const token = store.state.auth.token;

    // Set authorization header if token is available
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle the 401 response
      handle401();
    }
    return Promise.reject(error);
  }
);

function handle401() {
  const currentRoute = router.currentRoute.value.fullPath;
  const parts = currentRoute.split('?redirectTo=');
  const finalRedirectPath = parts[parts.length - 1];
  // Perform any action needed, e.g., logging out the user
  // Remove user token or any authentication data
  localStorage.removeItem('user-token');
  store.dispatch('logout');
  // Redirect to login page or show a message
  router.push({ name: 'Login', query: { redirectTo: finalRedirectPath } });
}

export default api;
