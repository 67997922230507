<template>
    <div class="flex justify-between pb-2 mb-2">
      <a-typography-title :level="5">{{ title }}</a-typography-title>
      <div class="flex justify-between pb-2 mb-2">
        <a-input-password :value="value" :placeholder="placeholder" />
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['title', 'value', 'placeholder']
  };
  </script>
  