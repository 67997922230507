import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
const base_path = '/api/admin/v1/projects';

const projectAPI = {
  async getAllOwnedProjects(userId) {
    return api
      .get(`${base_path}/list/${userId}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
  async getAllSharedProjects(userId) {
    return api
      .get(`${base_path}/accessed_projects_list/${userId}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
  async createProject(data) {
    return api
      .post(base_path, { project: data })
      .then((response) => {
        $toast.success('Project Created');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
  async updateProject(project_id, data) {
    return api
      .put(`${base_path}/${project_id}`, { project: data })
      .then((response) => {
        if (response.data.nestedComponents) {
          $toast.success('Project Updated');
        }
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  async regenerateAPIToken(project_id) {
    return api
      .post(`${base_path}/generate-token`, {project_id})
      .then((response) => {
        if (response.data.nestedComponents) {
          $toast.success('Project Updated');
        }
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  async deleteProject(project_id) {
    return api
      .delete(`${base_path}/${project_id}`)
      .then((response) => {
        $toast.success('Project Deleted');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
  async inviteCollaborator({project_id, invitee_email}) {
    return api.post(`${base_path}/send_project_invitation`, {
        project_id: project_id,
        invitee_email: invitee_email,
      })
      .then((response) => {
        $toast.success('Email has been sent');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Oops... Try again');
        return error.response.data;
      });
  },
  async acceptProjectInvitation(token) {
    return api
      .post(`${base_path}/accept_project_invitation`, { token })
      .then((response) => {
        $toast.success('Invitation Accepted');
        return response.data;
      })
      .catch((error) => {
        $toast.error('The link has expired');
        throw error;
      });
  },
  async getAllProjectCollaborators(project_id) {
    return api
      .get(`${base_path}/collaborators/${project_id}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
  async revokeProjectAccess({project_id, invitee_id}) {
    return api
      .post(`${base_path}/revoke_project_access/`, {project_id, invitee_id})
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Oops... Try again');
        throw error;
      });
  },
  
};

export default projectAPI;
