<template>
  <div class="container">

    <CollaboratorsSection :project_id="this.project_id" v-if="!isShared"/>

    <a-divider>Config</a-divider>

    <MaintenanceSection :config="this.config" @submit-maintenance="maintenanceFormSubmit" />
    <ProjectTokens :project="this.project" @generate-token="generateNewAPIToken" :isShared="isShared"/>
    <EnvironmentSection :config="this.config" :project_id="this.project_id" @update-config="updateSystemEnv"
      @add-env="addUserEnv" @delete-env="deleteUserEnv" />

    <div v-if="this.buildStatus && this.buildStatus !== 'not_found'" class="row">
      <a-steps :current="this.statusIndexes[this.buildStatus]"
        :status="this.statusIndexes[this.buildStatus] ? '' : 'error'" :items="[
          {
            title: 'Available',
          },
          {
            title: 'Scheduled',
          },
          {
            title: 'In Progress',
          },
          {
            title: 'Completed',
          },
        ]"></a-steps>
    </div>

    <div class="row" v-if="this.buildExist">
      <a-typography-title :level="5">Download Build</a-typography-title>

      <div>
        <a-button type="primary" :size="'default'" @click="handleDownloadBuildClick">
          <template #icon>
            <DownloadOutlined />
          </template>
          Download
        </a-button>
      </div>
    </div>

    <div v-if="this.buildStatus && this.buildStatus !== 'not_found'">
      <a-button type="primary" @click="showModal">Show Logs</a-button>
      <a-modal v-model:open="open" width="1000px" title="Logs" @ok="handleOk" @cancel="handleOk">
        <pre class="modal-logs">{{ buildLogs }}</pre>
      </a-modal>
    </div>
  </div>
</template>

<script>
import CollaboratorsSection from './CollaboratorsSection.vue';
import MaintenanceSection from './MaintenanceSection.vue';
import ProjectTokens from './ProjectTokens.vue';
import EnvironmentSection from './EnvironmentSection.vue';

import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { useToast } from 'vue-toast-notification';
import androidBuildAPI from '../../../api/androidBuild';

const $toast = useToast();

export default {
  name: 'ConfigPage',
  components: {
    DownloadOutlined,
    PlusOutlined,
    CollaboratorsSection,
    MaintenanceSection,
    ProjectTokens,
    EnvironmentSection
  },
  setup() {
    const current = ref(0);
    const refMaintenance = ref(null);
    const refProjectID = ref(null);
    const refProjectToken = ref(null);
    const refApiToken = ref(null);
    const refDisplayName = ref(null);
    const refPackageName = ref(null);
    const refGenerateBuild = ref(null);

    return {
      current,
      refMaintenance,
      refProjectID,
      refProjectToken,
      refApiToken,
      refDisplayName,
      refPackageName,
      refGenerateBuild,
    };
  },
  data() {
    return {
      statusIndexes: {
        not_found: -1,
        available: 1,
        scheduled: 2,
        executing: 3,
        completed: 4,
      },
      isShared: true,
      buildStatusIntervalId: null,
      buildLogIntervalId: null,
      buildStatus: null,
      buildExist: false,
      buildLogs: null,
      userId: this.$store.getters.getUserID,
      project_id: this.$route.params.project_id,
      open: false,
      inviteModalOpen: false,
      config: {
        login: {
          enabled: false,
        },
        maintenance: {
          enabled: false,
        },
        system_env: {
          package_name: null,
          display_name: null,
        },
        user_env: {}
      },
      userEnvForm: []
    };
  },
  mounted() {
    this.initConfig();
    this.getAllOwnedProjects(this.userId);
    this.getAllSharedProjects(this.userId);
    // this.getBuildStatus();
    // this.getBuildExist();
    // this.buildStatusIntervalId = setInterval(this.getBuildStatus, 20000);
  },
  unmounted() {
    clearInterval(this.buildStatusIntervalId);
    clearInterval(this.buildLogIntervalId);
  },
  methods: {
    ...mapActions([
      'getConfigs',
      'requireLogin',
      'enableMaintenance',
      'getAllOwnedProjects',
      'getAllSharedProjects',
      'updateConfig',
      'updateSystemEnv',
      'updateUserEnv',
      'removeUserEnv',
      'regenerateAPIToken'
    ]),
    ...mapGetters(['getConfigByName', 'configList']),
    initConfig() {
      let promise = this.getConfigs(this.project_id);
      promise.then((config) => {
        if (config) {
          config.forEach((element) => {
            this.config[element.name] = element.value;
          });
        }


        if (this.config.user_env) {
          Object.keys(this.config.user_env).forEach((key) => {
            this.userEnvForm.push({ name: key, value: this.config.user_env[key] })
          })
        }
      });
    },
    maintenanceFormSubmit() {
      const body = {
        enabled: this.config.maintenance.enabled,
        project_id: this.project_id,
      };
      this.enableMaintenance(body);
    },
    generateNewAPIToken() {
      this.regenerateAPIToken({ project_id: this.project_id });
    },
    showModal() {
      this.open = true;
      this.buildLogIntervalId = setInterval(this.getBuildLogs, 5000);
    },
    handleOk() {
      this.open = false;
      clearInterval(this.buildLogIntervalId);
    },
    deleteUserEnv(env) {
      const body = {
        project_id: this.project_id,
        name: env.name,
      };
      this.removeUserEnv(body).then(() => {
        this.userEnvForm = [];
        this.initConfig();
      });
    },
    addUserEnv(env) {
      if (!env.name || !env.value) return;
      const body = {
        value: env.value,
        name: env.name,
        project_id: this.project_id,
      };
      this.updateUserEnv(body);
    },
    async handleGenerateBuildClick() {
      try {
        await androidBuildAPI.generateAndroidBuild(this.project_id);
        this.buildStatus = 'available';
        this.buildStatusIntervalId = setInterval(this.getBuildStatus, 20000);
        $toast.success('Build started');
      } catch (e) {
        $toast.error(e.message);
      }
    },
    async getBuildStatus() {
      try {
        const data = await androidBuildAPI.getBuildStatus(this.project_id);
        this.buildStatus = data?.status;
        if (!this.buildStatus) return;

        if (
          this.buildStatus === 'completed' ||
          this.buildStatus === 'not_found'
        ) {
          if (this.buildStatus === 'completed') {
            this.buildExist = true;
          }

          clearInterval(this.buildStatusIntervalId);
        }
      } catch (e) {
        $toast.error(e.message);
      }
    },
    async getBuildExist() {
      await androidBuildAPI.checkBuildExist(this.project_id);
      this.buildExist = true;
    },
    async getBuildLogs() {
      const logs = await androidBuildAPI.getBuildLogs(this.project_id);
      this.buildLogs = logs;
    },
    async handleDownloadBuildClick() {
      try {
        const response = await androidBuildAPI.downloadBuildFile(
          this.project_id
        );
        // Create a URL from the Blob
        const url = window.URL.createObjectURL(new Blob([response]));

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.project_id}.apk`); // Set the file name

        // Append to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      } catch (e) {
        $toast.error('File not found');
      }
    },
  },
  computed: {
    project: {
      get() {
        const sharedProject = this.$store.getters.sharedProjectList.find(
          (x) => x.id === this.project_id
        );

        if (sharedProject) {
          this.isShared = true;
          return sharedProject;
        }

        this.isShared = false;
        return this.$store.getters.projectList.find(
          (x) => x.id === this.project_id
        );
      },
    },
  },
};
</script>
<style scoped>
.row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.modal-logs {
  max-height: 600px;
}

:deep(.input) {
  max-width: 50%;
}
</style>
