<template>
  <div>
    <a-divider>Collaborators</a-divider>

    <a-list v-if="collaborators.length > 0" :data-source="collaborators" item-layout="horizontal"
      class="collaborators-list">
      <template #renderItem="{ item }">
        <a-list-item class="collaborator-item">
          <a-list-item-meta>
            <template #avatar>
              <a-avatar :src="item.avatar" :alt="item.name || item.email">
                <template #icon>
                  <UserOutlined />
                </template>
              </a-avatar>
            </template>
            <template #title>
              <span class="collaborator-name">{{ item.name || '' }}</span>
            </template>
            <template #description>
              <a class="collaborator-email">{{ item.email }}</a>
            </template>
          </a-list-item-meta>

          <!-- Popconfirm for Delete -->
          <a-popconfirm title="Are you sure you want to delete this collaborator?"
            @confirm="removeCollaborator(item)" ok-text="Yes" cancel-text="No">
            <DeleteOutlined
              class="text-gray-400 cursor-pointer hover:text-red-500 hover:scale-110 transition-all duration-200" />
          </a-popconfirm>

        </a-list-item>
      </template>
    </a-list>

    <a-empty v-else description="No collaborators found" class="empty-state" />

    <div class="flex items-center justify-end">
      <invite-button />
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import InviteButton from './InviteButton.vue';
import { UserOutlined, DeleteOutlined } from '@ant-design/icons-vue';

export default {
  components: { InviteButton, UserOutlined, DeleteOutlined },
  props: {
    project_id: String
  },
  setup(props) {
    const store = useStore();

    const collaborators = computed(() => {
      return store.getters.collaboratorList;
    });

    const removeCollaborator = (collaborator) => {
      store.dispatch('revokeProjectAccess', {project_id: props.project_id, invitee_id: collaborator.id})
    };

    const onMount = () => {
      store.dispatch('getAllProjectCollaborators', {project_id: props.project_id});
      // console.log('Component mounted with project_id:', props.project_id);
      // Add initialization logic here
    };

    onMounted(onMount);

    return { collaborators, removeCollaborator };
  }
};
</script>