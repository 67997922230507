import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();

const authAPI = {
  async login(email, password) {
    return api
      .post('/api/admin/v1/user/login', { email, password })
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.data.message === 'wrong_login_or_password') {
          $toast.error('Wrong login or password');
        } else {
          $toast.error('Opps... Try again');
         }
        throw error;
      });
  },
  async register(email, password) {
    return api
      .post('/api/admin/v1/user/register', { email, password })
      .then((response) => {
        $toast.success('Registered');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async forgotPassword(email) {
    return api
      .post('/api/admin/v1/user/forgot-password', { email })
      .then((response) => {
        $toast.success('Email has been sent');
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  async validateForgotPasswordToken(token, user_id) {
    return api
      .post('/api/admin/v1/user/validate-forgot-password-token', { token, user_id })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        $toast.error('The link has expired');
        throw error;
      });
  },
};

export default authAPI;
