<template>
  <div class="constructor">
    <!-- Main Container -->
    <!-- Component Search and List -->
    <section class="component-section">
      <a-input-search v-model:value="searchComponentName" placeholder="input name" />
      <div class="component-list scrollable mb-3">
        <ComponentList :list="componentList" />
      </div>
    </section>

    <!-- Phone Image with Nested Constructor -->
    <section class="phone-container">
      <img :src="require('@/assets/phone_1.png')" alt="Phone" class="phone-image" />
      <div class="nested-constructor scrollable">
        <div v-if="loadingAIAnswer" class="flex justify-center items-center min-h-screen">
          <a-spin size="large" />
        </div>
        <NestedConstructor class="dropzone" :list="onPageComponents" />
      </div>
    </section>

    <!-- Configuration Menu -->
    <section :class="{ 'constructor-config-menu relative': true, 'expanded': isConfigMenuExpanded }">
      <div class="row justify-center">
        <!-- Back Button -->
        <a-tooltip title="Back">
          <a-button class="flex items-center mr-1 group" shape="round" type="default" ghost size="small"
            @click="navigateBack()">
            <template #icon>
              <ArrowLeftOutlined />
            </template>
            <span class="ml-2 text-sm">Back</span>
          </a-button>
        </a-tooltip>

        <!-- AI Button -->
        <a-tooltip title="AI">
          <a-button class="flex items-center mr-1 group" shape="round" type="default" ghost size="small"
            @click="toggleAIInput">
            <template #icon>
              <RobotOutlined />
            </template>
          </a-button>
        </a-tooltip>

        <!-- Save Button -->
        <a-tooltip title="Save">
          <a-button class="flex items-center mr-1 group" shape="round" type="default" ghost size="small"
            @click="save()">
            <template #icon>
              <SaveOutlined />
            </template>
          </a-button>
        </a-tooltip>

        <!-- Save and Broadcast Button -->
        <a-tooltip title="Save and broadcast">
          <a-button class="flex items-center mr-1 group" shape="round" type="default" ghost size="small"
            @click="saveAndBroadcast()">
            <template #icon>
              <ShareAltOutlined />
            </template>
          </a-button>
        </a-tooltip>

        <!-- Import Button -->
        <a-tooltip title="Import">
          <a-button class="flex items-center mr-1 group" shape="round" type="default" ghost size="small"
            @click="triggerFileInput()">
            <template #icon>
              <ImportOutlined />
            </template>
          </a-button>
        </a-tooltip>

        <!-- Export Button -->
        <a-tooltip title="Export">
          <a-button class="flex items-center mr-1 group" shape="round" type="default" ghost size="small"
            @click="exportToFile()">
            <template #icon>
              <ExportOutlined />
            </template>
          </a-button>
        </a-tooltip>

        <!-- Doc Button -->
        <a-tooltip title="Doc">
          <a-button class="flex items-center mr-1 group" shape="round" type="default" ghost size="small">
            <router-link :to="{ name: 'BeActionsDoc' }" class="register-link ml-1" target="_blank">Doc</router-link>
            <template #icon>
              <InfoCircleOutlined />
            </template>
          </a-button>
        </a-tooltip>

        <!-- Fullscreen Button -->
        <a-tooltip title="Fullscreen">
          <a-button class="flex items-center mr-1 group" shape="round" type="default" ghost size="small"
            @click="toggleFullscreen">
            <template #icon>
              <ExpandOutlined />
            </template>
          </a-button>
        </a-tooltip>
      </div>
      <ConstructorConfigMenu :isNewEditor="isNewEditor" />

      <!-- AI Input at the bottom of the page -->
      <div v-if="showAIInput" class="absolute bottom-2 left-0 w-full">
        <div class="flex items-center">
          <textarea :disabled="loadingAIAnswer" type="text" v-model="aiRequest" :rows="aiTextAreaRows"
            placeholder="Enter your AI request..."
            class="w-full p-1 pr-5 pl-5 rounded-md bg-[#1E1E1E] text-white focus:outline-none focus:ring-0 hover:ring-0"
            @keydown="handleKeydown"></textarea>
        </div>
      </div>

    </section>

    <!-- File Input -->
    <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none" accept=".json" />
  </div>
</template>


<script>
import { componentListSearch } from '@/store/modules/data';
import { mapActions } from 'vuex';
import ComponentList from './../constructor/ComponentList.vue';
import NestedConstructor from '../constructor/helpers/NestedConstructor.vue';
import ConstructorConfigMenu from './../constructor/ConstructorConfigMenu.vue';
import {
  ExportOutlined,
  ImportOutlined,
  SaveOutlined,
  ShareAltOutlined,
  InfoCircleOutlined,
  ExpandOutlined,
  ArrowLeftOutlined,
  RobotOutlined
} from '@ant-design/icons-vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import { uuid } from 'vue-uuid';
import router from '@/router';
const MAX_ROWS = 4;

export default {
  name: 'ConstructorPage',
  components: {
    ComponentList,
    NestedConstructor,
    ConstructorConfigMenu,
    ExportOutlined,
    ImportOutlined,
    SaveOutlined,
    ShareAltOutlined,
    InfoCircleOutlined,
    ExpandOutlined,
    ArrowLeftOutlined,
    RobotOutlined
  },
  data() {
    return {
      screen_id: this.$route.params.screen_id,
      userId: this.$store.getters.getUserID,
      screen: null,
      searchComponentName: '',
      aiTextAreaRows: 1,
      loadingAIAnswer: false,
      aiRequest: '',
      // componentList: this.$store.getters.allComponents,
      isConfigMenuExpanded: false,
      isNewEditor: true,
      showAIInput: true
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: 'init',
  },
  methods: {
    ...mapActions([
      'getAllCustomWidgets',
      'getScreenByID',
      'updateScreen',
      'updateAndBroadcastScreen',
      'getScreen',
      'generateAIContent'
    ]),

    navigateBack() {
      router.go(-1)
    },

    saveAndBroadcast() {
      const screen = this.$store.getters.screens[this.screen_id];

      let params = {
        nestedComponents: this.onPageComponents,
      };
      let body = {
        name: screen.name,
        body: params,
      };
      this.updateAndBroadcastScreen({
        screen_id: this.screen_id,
        data: body,
      });
    },
    save() {
      const screen = this.$store.getters.screens[this.screen_id];

      let params = {
        nestedComponents: this.onPageComponents,
      };
      let body = {
        name: screen.name,
        body: params,
      };
      this.updateScreen({ screen_id: this.screen_id, data: body });
    },
    init() {
      if (!this.screen_id) return;
      this.getScreen(this.screen_id);
      this.getAllCustomWidgets(this.userId);
    },
    exportToFile() {
      const jsonData = JSON.stringify(this.onPageComponents, null, 2);
      // Creating a downloadable JSON file
      const blob = new Blob([jsonData], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${this.$route.params.screen_id}.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const jsonData = JSON.parse(e.target.result);
            this.onPageComponents = jsonData; // Update your state with the imported data
            // Optionally, save the imported data to the store or process it further
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        };
        reader.readAsText(file);
      }
    },
    import() {
      this.triggerFileInput();
    },
    toggleConfigMenuWidth() {
      this.isConfigMenuExpanded = !this.isConfigMenuExpanded;
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        // Request fullscreen
        document.documentElement.requestFullscreen();
      } else {
        // Exit fullscreen
        document.exitFullscreen();
      }
    },
    toggleAIInput() {
      this.showAIInput = !this.showAIInput;
    },
    handleKeydown(event) {
      if (event.shiftKey && event.key === 'Enter') {
        event.preventDefault();
        this.aiTextAreaRows = Math.min(this.aiTextAreaRows + 1, MAX_ROWS);
        this.aiRequest += '\n';
      } else if (event.key === 'Enter') {
        event.preventDefault();
        this.submitAIRequest();
      }
    },
    async submitAIRequest() {
      this.loadingAIAnswer = true;
      const component = await this.generateAIContent({ prompt: this.aiRequest });
      if (component.message) {
        this.onPageComponents.push(component.message);
        this.aiTextAreaRows = 1;
        this.aiRequest = '';
      }
      this.loadingAIAnswer = false;
    }
  },
  computed: {
    onPageComponents: {
      get() {
        return this.$store.getters.onPageComponents;
      },
      set(value) {
        this.$store.commit('updateOnPageComponents', value);
      },
    },
    componentList: {
      get() {
        const widgets = this.$store.getters.customWidgetList.map(x => {
          return {
            "id": uuid.v4(),
            "memo": "",
            "display_name": x.name,
            "name": "CustomWidget",
            "data": {
              "widget_id": x.id
            },
            "nestedComponents": []
          }
        });

        const composedList = widgets.concat(this.$store.getters.allComponents);
        const searchString = this.searchComponentName?.toLowerCase().trim();

        if (searchString) {
          const results = componentListSearch.search(searchString);
          return results.map(result => {
            return composedList.find(item => item.id === result.id);
          });
        }

        return composedList;
      },
    },
  },
};
</script>

<style scoped>
.constructor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  max-height: 100vh;
  overflow: hidden;
  background-color: #1E1E1E;
}

.component-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.component-list {
  width: 270px;
  max-height: 100vh;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #ffffff;
}

.phone-container {
  position: relative;
  width: 400px;
  /* Adjust based on your phone image size */
  height: 800px;
  /* Adjust based on your phone image size */
}

.phone-image {
  width: 100%;
  height: 100vh;
  max-height: 800px;
}

.nested-constructor {
  position: absolute;
  top: 7%;
  left: 10%;
  width: 80%;
  height: 80%;
  overflow-y: auto;
}

.constructor-config-menu {
  width: 700px;
}

.constructor-config-menu.expanded {
  width: 800px;
}

.config-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.mr-5px {
  margin-right: 5px;
}

a-button {
  background-color: #007aff;
  border-color: #007aff;
  color: #ffffff;
  margin-right: 10px;
}

a-button:hover {
  background-color: #0056a3;
  border-color: #0056a3;
  opacity: 0.9;
}

a-switch {
  margin: 0 10px;
}

.doc-link {
  display: inline-block;
  color: #007aff;
  text-decoration: none;
}

.doc-link:hover {
  text-decoration: underline;
}
</style>
