import projectAPI from '../../api/projects';
import router from '@/router';

export default {
  actions: {
    async getAllOwnedProjects({ commit, state }, userId) {
      if (state.projectList.length > 0) return;

      try {
        const projectList = await projectAPI.getAllOwnedProjects(userId);
        commit('updateProjectList', projectList);
      } catch (error) {
        // Handle error if needed
      }
    },
    async getAllSharedProjects({ commit, state }, userId) {
      if (state.sharedProjectList.length > 0) return;

      try {
        const sharedProjectList = await projectAPI.getAllSharedProjects(userId);
        commit('updateSharedProjectList', sharedProjectList);
      } catch (error) {
        // Handle error if needed
      }
    },
    async createProject({ commit }, data) {
      try {
        const project = await projectAPI.createProject(data);
        commit('addToProjectList', project);
      } catch (error) {
        // Handle error if needed
      }
    },
    async updateProject({ commit }, { project_id, data }) {
      try {
        const updatedData = await projectAPI.updateProject(project_id, data);
        commit('updateProjectInList', { project_id, updatedData });
        return updatedData;
      } catch (error) {
        // Handle error if needed
      }
    },
    async regenerateAPIToken({ commit }, { project_id }) {
      try {
        const updatedData = await projectAPI.regenerateAPIToken(project_id);
        commit('updateProjectInList', { project_id, updatedData });
        return updatedData;
      } catch (error) {
        return error;
      }
    },
    async deleteProject({ commit }, project_id) {
      try {
        await projectAPI.deleteProject(project_id);
        commit('removeFromProjectList', project_id);
      } catch (error) {
        // Handle error if needed
      }
    },
    async inviteCollaborator(_context, { project_id, email }) {
      try {
        return await projectAPI.inviteCollaborator({ project_id: project_id, invitee_email: email });
      } catch (error) {
        return error;
      }
    },
    async acceptProjectInvitation(_context, { token }) {
      try {
        await projectAPI.acceptProjectInvitation(token);
      } catch (error) {
        return error;
      } finally{
        router.push({ name: 'SharedProjectList' });
      }
    },
    async getAllProjectCollaborators({ commit, _state }, {project_id}) {
      try {
        const collaboratorList = await projectAPI.getAllProjectCollaborators(project_id);
        commit('updateProjectCollaboratorList', collaboratorList);
      } catch (error) {
        // Handle error if needed
      }
    },
    async revokeProjectAccess({ dispatch }, {project_id, invitee_id}) {
      try {
        await projectAPI.revokeProjectAccess({project_id, invitee_id});
        dispatch('getAllProjectCollaborators', {project_id});
      } catch (error) {
        // Handle error if needed
      }
    },
  },
  mutations: {
    updateProjectList(state, projectList) {
      state.projectList = projectList;
    },
    updateSharedProjectList(state, projectList) {
      state.sharedProjectList = projectList;
    },
    updateProjectCollaboratorList(state, collaboratorList) {
      state.collaboratorList = collaboratorList;
    },
    addToProjectList(state, project) {
      state.projectList.push(project);
    },
    updateProjectInList(state, { project_id, updatedData }) {
      const index = state.projectList.findIndex(
        (project) => project.id === project_id
      );
      if (index !== -1) {
        // Ensure to maintain the same reference if you want to keep reactivity
        state.projectList.splice(index, 1, {
          ...state.projectList[index],
          ...updatedData,
        });
      }
    },
    removeFromProjectList(state, project_id) {
      state.projectList = state.projectList.filter(
        (project) => project.id !== project_id
      );
    },
  },
  state: {
    projectList: [],
    sharedProjectList: [],
    collaboratorList: []
  },
  getters: {
    projectList(state) {
      return state.projectList;
    },
    sharedProjectList(state) {
      return state.sharedProjectList;
    },
    collaboratorList(state) {
      return state.collaboratorList;
    }
  },
};
